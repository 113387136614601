import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { Fonts } from "./styles/Fonts";
import { theme } from "./theme/theme";
import { ApolloClient, InMemoryCache, ApolloProvider, gql, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from "./hooks/token";
import { BASE_URL } from "./common/constant";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: `${BASE_URL}/api/graphql/`,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token,
    }
  }
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});


const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink, errorLink]),
  cache: new InMemoryCache(),
});


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ChakraProvider theme={extendTheme(theme)} resetCSS={true} >
        <Fonts />
        <RouterProvider router={router} />
      </ChakraProvider>
    </ApolloProvider>
  </React.StrictMode>
);
