import { Global } from '@emotion/react';

export const Fonts = () => (
    <Global
        styles={`
                    @font-face {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        src: url(
                            '/fonts/roboto/roboto-v16-latin-regular.eot'
                        ); /* IE9 Compat Modes */
                        src: local('Roboto'), local('Roboto-Regular'),
                        url(
                            '/fonts/roboto/roboto-v16-latin-regular.woff2'
                        ) format('woff2'),
                        /* Super Modern Browsers */
                        url(
                            '/fonts/roboto/roboto-v16-latin-regular.woff'
                        ) format('woff'),
                        /* Modern Browsers */ 
                        url(
                            '/fonts/roboto/roboto-v16-latin-regular.ttf'
                        ) format('truetype'),
                        /* Safari, Android, iOS */
                        url(
                            '/fonts/roboto/roboto-v16-latin-regular.svg#Roboto'
                        ) format('svg'); /* Legacy iOS */
                    };
                    @font-face {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        src: url(
                            '/fonts/open-sans/open-sans-v15-latin-regular.eot'
                        ); /* IE9 Compat Modes */
                        src: local('Open Sans Regular'), local('OpenSans-Regular'),
                        url(
                            '/fonts/open-sans/open-sans-v15-latin-regular.eot?#iefix'
                        ) format('embedded-opentype'), /* IE6-IE8 */
                        url(
                            '/fonts/open-sans/open-sans-v15-latin-regular.woff2'
                        ) format('woff2'), /* Super Modern Browsers */
                        url(
                            '/fonts/open-sans/open-sans-v15-latin-regular.woff'
                        ) format('woff'), /* Modern Browsers */
                        url(
                            '/fonts/open-sans/open-sans-v15-latin-regular.ttf'
                        ) format('truetype'), /* Safari, Android, iOS */
                        url(
                            '/fonts/open-sans/open-sans-v15-latin-regular.svg#OpenSans'
                        ) format('svg'); /* Legacy iOS */
                    };
                    @font-face {
                        font-family: 'Avenir Book';
                        font-style: normal;
                        font-weight: 400;
                        src: url(
                            '/fonts/avenir-book/Avenir Book.eot'
                        ), /* IE9 Compat Modes */
                        url(
                            '/fonts/avenir-book/Avenir Book.woff2'
                        ) format('woff2'), /* Super Modern Browsers */
                        url(
                            '/fonts/avenir-book/Avenir Book.woff'
                        ) format('woff'), /* Modern Browsers */
                        url(
                            '/fonts/avenir-book/Avenir Book.ttf'
                        ) format('truetype'); /* Safari, Android, iOS */
                    };
                    @font-face {
                        font-family: 'clear-sans';
                        src: local('ClearSans'), local('ClearSans-Regular'),
                        src: url(
                            '/fonts/clear-sans/ClearSans-Regular-webfont.eot'
                        );
                        url(
                            '/fonts/clear-sans/ClearSans-Regular-webfont.woff2'
                        ) format('woff2'),
                        url(
                            '/fonts/clear-sans/ClearSans-Regular-webfont.woff'
                        ) format('woff'),
                        url(
                            '/fonts/clear-sans/ClearSans-Regular-webfont.ttf'
                        ) format('truetype'),
                        url(
                            '/fonts/clear-sans/ClearSans-Regular-webfont.svg#clear_sansregular'
                        ) format('svg');
                        font-weight: 400;
                        font-style: normal;
                    }
                    @font-face {
                        font-family: 'Segoe UI';
                        font-style: normal;
                        font-weight: 400;
                        src: url(
                            '/fonts/segoe-ui/Segoe UI.eot'
                        ); /* IE9 Compat Modes */
                        src: url(
                            '/fonts/segoe-ui/Segoe UI.woff2'
                        ) format('woff2'), /* Super Modern Browsers */
                        url(
                            '/fonts/segoe-ui/Segoe UI.woff'
                        ) format('woff'), /* Modern Browsers */
                        url(
                            '/fonts/segoe-ui/Segoe UI.ttf'
                        ) format('truetype'), /* Safari, Android, iOS */
                        url(
                            '/fonts/segoe-ui/Segoe UI.svg#SegoeUI'
                        ) format('svg'); /* Legacy iOS */
                    }
                `}
    />
);
