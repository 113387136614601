import { BASE_URL } from "../common/constant";
import { getToken } from "../hooks/token";
import { fetchAPI } from "./fetchAPI";

export const getBanks = async () => {
  const url = `${BASE_URL}/api/v1/banks`;
  const token = getToken();
  const headers = new Headers();
  headers.append("Authorization", token);
  return fetchAPI(url, {
      method: "GET",
      headers: headers,
  }).then((res) => res.json());
    
}