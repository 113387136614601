export const Select = {
    // The styles all dropdowns have in common
    baseStyle: {
        fontFamily: 'inherit',
        fontSize: '100%',
        lineHeight: '1.15',
        margin: '0',
        padding: '0',
        color: 'inherit',
        textTransform: 'none',
    },
};
