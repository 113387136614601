import React from 'react';
import { getBanks } from '../api/filters';

interface ContextProps {
  banks: Record<string, string>[];
  bankMapping: Record<string, string>;
  fetchBanks: () => void;
}

export const CommonContext = React.createContext<ContextProps>({
  banks: [],
  bankMapping: {},
  fetchBanks: () => {},
});

export const CommonContextWrapper = ({ children, ...props}: any) => {
  const [banks, setBanks] = React.useState([]);
  const [bankMapping, setBankMapping] = React.useState<Record<string, string>>(
    {}
  );
  
  const fetchBanks = async () => {
    try {
      const banks = await getBanks();
      const mapping: any = {};
      banks?.data?.forEach((bank: any) => {
        mapping[bank.bank_code] = bank.bank_name;
      });
      setBankMapping(mapping);
      setBanks(banks.data);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    fetchBanks();
  }, []);

  return <CommonContext.Provider value={{fetchBanks, banks, bankMapping}}>
    {children}
  </CommonContext.Provider>
}

export default CommonContextWrapper;