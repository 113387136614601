import { gql } from "@apollo/client";

export const FILE_QUERY = gql`
  query GetFile($filesId: ID!) {
    files(id: $filesId) {
      id
      inputFile
      fileUuid
      parsingStatus
      originalFile
      taskId
      stashboardJobId
      parentUserId
      bankStatus
      bankPredictedBy
      params
      subtype
      mUser {
        id
        userId
        __typename
      }
      numPagesWithContent
      createdAt
      updatedAt
      bankCode
      pageRange
      outputExcel
      canopyUlExcel
      historySet {
        edges {
          node {
            historyUuid
            parsingStatus
            createdAt
            taskId
          }
        }
      }
      __typename
    }
  }
`;
