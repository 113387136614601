import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React from "react";
// @ts-ignore
import { endOfDay, format, isSameDay, startOfDay, subDays } from "date-fns";
import { NotVoid } from "lodash";
import { DateRangePicker } from "react-date-range";
import { useCurrentColors } from "../../hooks/UseCurrentColors";

export interface ICanopyDateRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: "selection";
}

export const CanopyDateRangePicker: React.FC<{
  dateRange: ICanopyDateRange;
  onChange: (selection: any) => NotVoid;
}> = ({ dateRange, onChange }) => {
  const currentColors = useCurrentColors();

  const handleChange = (selection: any) => {
    selection && onChange && onChange(selection);
  };

  return (
    <Popover placement="left">
      <PopoverTrigger>
        <Flex alignItems="center" gap={1}>
          <Box
            w={100}
            bg={currentColors.componentBg}
            border={`1px solid ${currentColors.brand["600"]}`}
            px={3}
            py={2}
            borderRadius={4}
            textAlign="center"
            cursor="pointer"
          >
            {dateRange?.startDate ? (
              format(dateRange?.startDate, "dd/MM/yyyy")
            ) : (
              <Text color={currentColors.brand["500"]}>From</Text>
            )}
          </Box>
          -
          <Box
            w={100}
            bg={currentColors.componentBg}
            border={`1px solid ${currentColors.brand["600"]}`}
            px={3}
            py={2}
            borderRadius={4}
            textAlign="center"
            cursor="pointer"
          >
            {dateRange?.endDate ? (
              format(dateRange?.endDate, "dd/MM/yyyy")
            ) : (
              <Text color={currentColors.brand["500"]}>To</Text>
            )}
          </Box>
        </Flex>
      </PopoverTrigger>
      <PopoverContent mt={20} w={600}>
        <PopoverArrow />
        <PopoverBody>
          <DateRangePicker
            onChange={(item: any) => handleChange(item.selection)}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={
              dateRange.startDate && dateRange.endDate
                ? [dateRange]
                : [
                    {
                      ...dateRange,
                      startDate: new Date(),
                      endDate: new Date(),
                    },
                  ]
            }
            direction="horizontal"
            rangeColors={[currentColors.primaryColor]}
            maxDate={new Date()}
            inputRanges={[]}
            staticRanges={[
              {
                label: "Today",
                range: () => ({
                  startDate: startOfDay(new Date()),
                  endDate: new Date(),
                }),
                isSelected(range: any) {
                  const definedRange: any = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "Yesterday",
                range: () => ({
                  startDate: startOfDay(subDays(new Date(), 1)),
                  endDate: endOfDay(subDays(new Date(), 1)),
                }),
                isSelected(range: any) {
                  const definedRange: any = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "Last 7 Days",
                range: () => ({
                  startDate: startOfDay(subDays(new Date(), 7)),
                  endDate: new Date(),
                }),
                isSelected(range: any) {
                  const definedRange: any = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "Last 30 Days",
                range: () => ({
                  startDate: startOfDay(subDays(new Date(), 30)),
                  endDate: new Date(),
                }),
                isSelected(range: any) {
                  const definedRange: any = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "Clear",
                range: () => ({
                  startDate: undefined,
                  endDate: undefined,
                }),
                isSelected(range: any) {
                  const definedRange: any = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
            ]}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
