import { gql } from "@apollo/client";

export const RE_PROCESS_STATEMENT = gql`
  mutation ReProcessStatement($bank: String!, $fileUuid: String!, $subtype: String!) {
    reProcessStatement(bank: $bank, fileUuid: $fileUuid, subtype: $subtype) {
      message
      success
    }
  }
`;
