import { gql } from "@apollo/client";

export const ALL_USERS = gql`
  query AllUsers {
    allUsers {
      edges {
        node {
          userId
          createdAt
          updatedAt
          groupId
          roleId
        }
      }
    }
  }
`;
