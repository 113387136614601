import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { IDocument } from "../../../common/constant";
import { DocumentListItem } from "./DocumentListItem";

export const DocumentList: React.FC<{
  documentList: IDocument[];
  isDisabled: boolean;
  onChange: (document: IDocument) => void;
  onDelete: (document: IDocument) => void;
}> = ({ documentList, isDisabled, onChange, onDelete }) => {
  return (
    <Box p="16px">
      <Flex p="16px" alignItems="center">
        <Box pl="8px" minW="190px" w="18%">
          <Text fontSize="lg"> File Name</Text>
        </Box>

        <Box>
          <Text fontSize="lg"> File Details</Text>
        </Box>
      </Flex>
      <Box borderRadius={4} className="card">
        {documentList.map((d, index) => (
          <DocumentListItem
            document={d}
            key={d.key}
            isDisabled={isDisabled}
            onChange={onChange}
            onDelete={onDelete}
            inprogress={d.status.inprogress}
          />
        ))}
      </Box>
    </Box>
  );
};
