import { gql } from "@apollo/client";

export const FILES_QUERY = gql`
  query GetAllFiles(
    $fileStatus: String
    $bankStatus: String
    $cursor: String
    $bankCode: String
    $clientId: String
    $fileContains: String
    $subtype: String
    $taskId: String
    $stashboardJobId: String
    $parentUserId: String
    $createdAtGte: DateTime
    $createdAtLte: DateTime
    $updatedAtGte: DateTime
    $updatedAtLte: DateTime
    $numPagesWithContentGte: Decimal
    $numPagesWithContentLte: Decimal
    $offset: Int
  ) {
    allFiles(
      first: 30
      parsingStatus: $fileStatus
      bankStatus: $bankStatus
      after: $cursor
      bankCode: $bankCode
      subtype: $subtype
      taskId: $taskId
      stashboardJobId: $stashboardJobId
      parentUserId: $parentUserId
      mUser_UserId: $clientId
      inputFile: $fileContains
      createdAtGte: $createdAtGte
      createdAtLte: $createdAtLte
      updatedAtGte: $updatedAtGte
      updatedAtLte: $updatedAtLte
      numPagesWithContentGte: $numPagesWithContentGte
      numPagesWithContentLte: $numPagesWithContentLte
      orderBy: "-updated_at"
      offset: $offset
    ) {
      edges {
        node {
          id
          inputFile
          fileUuid
          parsingStatus
          originalFile
          taskId
          stashboardJobId
          parentUserId
          bankStatus
          bankPredictedBy
          params
          subtype
          mUser {
            id
            userId
            __typename
          }
          numPagesWithContent
          createdAt
          updatedAt
          bankCode
          pageRange
          outputExcel
          canopyUlExcel
          historySet {
            edges {
              node {
                historyUuid
                parsingStatus
                createdAt
                taskId
              }
            }
          }
          __typename
        }
        __typename
      }
      pageInfo {
        endCursor
        hasNextPage
        __typename
      }
      totalCount
      edgeCount
      __typename
    }
  }
`;
