import { mode } from '@chakra-ui/theme-tools';
export const Tooltip = {
    baseStyle: (props: any) => ({
        p: '4px',
        lineHeight: '11px',
        fontSize: '11px',
        color: mode('light.tooltipTextColor', 'dark.tooltipTextColor')(props),
    }),
    variants: {},
};
