import JSZip from "jszip";
// import { saveAs } from 'file-saver'
import { BASE_URL } from "../common/constant";
import { getToken } from "../hooks/token";
import _ from "lodash";
import { fetchAPI } from "./fetchAPI";
const saveAs = require("file-saver");

export enum FileType {
  INPUT_PDF = "input_pdf",
  OUTPUT_EXCEL = "output_excel",
  CANOPY_UL = "canopy_ul",
}

const zipName = (type: FileType) => {
  if (type === FileType.INPUT_PDF) {
    return "canopy" + "-" + "pdf";
  } else if (type === FileType.OUTPUT_EXCEL) {
    return "canopy" + "-" + "excel";
  } else {
    return "canopy" + "-" + "ul";
  }
};

export const getFileName = (response: any) => {
  let name = "unknown.pdf";
  const header = response.headers.get("Content-Disposition");
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(header || "");
  if (matches != null && matches[1]) {
    name = matches[1].replace(/['"]/g, "");
  }
  return name;
};

export const downloadBlob = async (response: any, openFile = false) => {
  const name = getFileName(response);
  try {
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;

    if (openFile) {
      link.target = "_blank";
    } else {
      link.download = name;
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};

export const download = (id: string, type: FileType) => {
  const token = getToken();
  const url = `${BASE_URL}/api/get_file/`;

  const headers = new Headers();
  headers.append("Authorization", token);
  headers.append("Content-Disposition", "attachment");

  const data = new FormData();
  data.append("file_pk", id);
  data.append("response_file", type);

  return fetchAPI(url, {
    method: "POST",
    headers: headers,
    body: data,
  });
};

export const downloadFile = async (
  id: string,
  type: FileType,
  openFile = false
) => {
  try {
    const response: any = await download(id, type);
    if (response?.status === 200) {
      downloadBlob(response, openFile);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const downloadBulkFile = async (
  selectedFileIds: string[],
  type: FileType
) => {
  let counter = 0;
  const zip = new JSZip();
  selectedFileIds.forEach((id) => {
    download(id, type).then(async (response) => {
      try {
        const blob: any = await response.blob();
        const name = getFileName(response);
        if (response?.status === 200) {
          zip.file(name, blob, {
            binary: true,
          });
        }
      } catch (error) {
        console.error(error);
      }
      counter++;
      if (counter >= selectedFileIds.length) {
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, zipName(type) + ".zip");
      }
    });
  });
};

export const viewLogs = async (id: string) => {
  const token = getToken();
  const url = `${BASE_URL}/api/get_log/`;

  const headers = new Headers();
  headers.append("Authorization", token);
  headers.append("Content-Disposition", "attachment");

  const data = new FormData();
  data.append("task_id", id);

  try {
    let response: any = await fetchAPI(url, {
      method: "POST",
      headers: headers,
      body: data,
    });
    if (response?.status === 200) {
      const clonnedResponse = _.cloneDeep(response);
      downloadBlob(clonnedResponse);
      return response;
    }
  } catch (error) {
    console.error(error);
  }
};

export const downloadLogs = async (content: string = "", fileName: string) => {
  var blob = new Blob([content], { type: "text/plain;charset=utf-8" });
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
