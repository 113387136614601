import { mode } from '@chakra-ui/theme-tools';
export const Modal = {
    // The styles all button have in common
    parts: ['dialog'],
    baseStyle: (props: any) => ({
        dialog: {
            backgroundColor: mode(
                'light.componentBg',
                'dark.componentBg'
            )(props),
            top: '0px',
            borderRadius: '4px',
        },
    }),
    variants: {},
};
