import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useCurrentColors } from "../../hooks/UseCurrentColors";
import { MAX_FILE_SIZE, MAX_NUMBER_OF_JOB_OPERATIONS, MB_TO_BYTE_MULTIPLIER } from "../../common/constant";


export const UploadDocument: React.FC<{
  onFileSelect: (files: File[]) => void;
  isDisabled?: boolean
}> = ({ onFileSelect, isDisabled }) => {

  const toast = useToast()
  const toast_error_id = 'error-toast';
  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    accept: {
      'application/pd': ['.pdf'],
    },
    maxFiles: MAX_NUMBER_OF_JOB_OPERATIONS,
    maxSize: (MAX_FILE_SIZE * MB_TO_BYTE_MULTIPLIER),
  });
  const currentColors = useCurrentColors();

  useEffect(() => {
    acceptedFiles?.length && onFileSelect(acceptedFiles);
  }, [acceptedFiles]);

  if (fileRejections && fileRejections.length > 0) {
    if (!toast.isActive(toast_error_id)) {
      const groupedFiles = new Map();
      const errorMsgDict = new Map();
      fileRejections.map(({ file, errors }) => {
        errors.map((e) => {
          const fileList = groupedFiles.get(e.code);
          errorMsgDict.set(e.code, (e.code === "file-too-large" ? ("File is larger than " + MAX_FILE_SIZE.toString() + " MB") : e.message));
          if (fileList) {
            fileList.push(file.name);
          } else {
            groupedFiles.set(e.code, [file.name]);
          }
        });
      });
      const content = Array.from(groupedFiles).map(([key, filenames]) => {
        return (
          <li key={key}>
            {errorMsgDict.get(key)}
            <ul>
              {filenames.map((e: string) => (
                <li >{e}</li>))
              }
            </ul>
          </li>
        );

      });

      fileRejections.length = 0;
      toast({
        id:toast_error_id,
        title: "File Selection Error",
        status: "error",
        duration: 5000,
        isClosable: true,
        description: content,
      });
    }
  }

  return (
    <Box
      m={"auto"}
      width="1080px"
      mt="8px"
      bgGradient={`radial(${currentColors?.pageBg}, ${currentColors?.brand["700"]})`}
      borderRadius={4}
      border={`1px solid ${currentColors.componentBorder}`}
    >
      <Box
        _hover={{
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        {...getRootProps({ onClick: (event) => isDisabled && event.stopPropagation() })}
      >
        <input {...getInputProps()} />
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          height="150px"
        >
          <Flex
            justifyContent="flex-start"
            color={currentColors.whiteAlpha["300"]}
          >
            <Box px="16px">
              <MdOutlineCloudUpload fontSize="30px" />
            </Box>
            <Box>
              <Text fontSize="lg">Click here or drag file to upload</Text>
              <Text fontSize="sm">Only .pdf files are supported </Text>
              <Text fontSize="sm">Max size of each File: {MAX_FILE_SIZE} MB, Max number of files at a time: {MAX_NUMBER_OF_JOB_OPERATIONS}</Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
