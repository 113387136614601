import { BASE_URL, DATA_STORAGE_URL_BY_TOKEN_ISSUER, IDocument } from "../common/constant";
import { getToken, getParsedToken } from "../hooks/token";
import { fetchAPI } from "./fetchAPI";


export const getDataStorageUrl = (token: string) => {
  const pToken = getParsedToken(token)
  const parsed_iss = new URL(pToken.iss);
  let iss = null;
  if (parsed_iss.hostname.startsWith("cognito-idp."))
    iss = parsed_iss.pathname.replace("/","").trim()
  else
    iss = parsed_iss.hostname
  const storage_url = DATA_STORAGE_URL_BY_TOKEN_ISSUER[iss]
  return storage_url
}

export const validateFile = ({ filename }: { filename: string }) => {
  const token = getToken();
  const storage_url = getDataStorageUrl(token)
  const url = `${storage_url}/api/v1/upload/validate_filename`;

  const headers = new Headers();
  headers.append("Authorization", token);
  headers.append("Content-Type", "application/json");

  return fetchAPI(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ filename }),
  });
};

export const uploadFile = (document: IDocument) => {
  const token = getToken();
  const storage_url = getDataStorageUrl(token)
  const url = `${storage_url}/api/v1/upload`;

  const headers = new Headers();
  headers.append("Authorization", token);

  const data = new FormData();
  data.append("file", document.file);
  data.append('filename', document.file?.name)
  data.append("validate_password", "true");

  if (document?.params?.password) {
    data.append("validate_password", "true");
    data.append("password", document.params.password);
  }

  return fetchAPI(url, {
    method: "POST",
    headers: headers,
    body: data,
  }).then((response) => ({ response, document }));
};

interface IMapUploadedDocument {
  fileName: string;
  fileKey: string;
  bankCode?: string;
  password?: string;
  params?: string;
  pageRange?: string;
}

export const mapUploadedDocument = (payload: IMapUploadedDocument) => {
  const token = getToken();
  const url = `${BASE_URL}/api/v1/parse`;

  const headers = new Headers();
  headers.append("Authorization", token);
  headers.append("Content-Type", "application/json");

  return fetchAPI(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      file_name: payload.fileName,
      file_key: payload.fileKey,
      ...(payload.bankCode && { bank_code: payload.bankCode }),
      ...(payload.password && { password: payload.password }),
      ...(payload.params && { params: JSON.stringify(payload.params) }),
      ...(payload.pageRange && { page_range: payload.pageRange }),
    }),
  });
};
