import { StyleFunctionProps } from "@chakra-ui/react";
import { Button } from "./components/button";
import { Checkbox } from "./components/checkbox";
import { StyledInput } from "./components/input";
import { Modal } from "./components/modal";
import { Select } from "./components/select";
import { Text } from "./components/text";
import { Tooltip } from "./components/tooltip";
import { IconButton } from "./components/iconButton";
import { Dict } from "@chakra-ui/utils";
import { mode } from "@chakra-ui/theme-tools";

export const theme = {
  initialColorMode: "light",
  useSystemColorMode: false,
  components: {
    Button,
    Select,
    Text,
    Modal,
    Tooltip,
    Checkbox,
    IconButton,
  },
  breakpoints: {
    base: "0em",
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  },
  colors: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000000",
    white: "#FFFFFF",
    whiteAlpha: {
      "10": "#121212",
      "50": "#141414",
      "100": "#1E1E1E",
      "200": "#323232",
      "300": "#4B4B4B",
      "400": "#6E6E6E",
      "500": "#848484",
      "600": "#D3D3D3",
      "700": "#DBDBDB",
      "800": "#F2F2F2",
      "900": "#F8F8F8",
      "950": "#EEEEEE",
      "999": "#EFEFEF",
    },
    blackAlpha: {
      "50": "rgba(0, 0, 0, 0.04)",
      "100": "rgba(0, 0, 0, 0.06)",
      "200": "rgba(0, 0, 0, 0.08)",
      "300": "rgba(0, 0, 0, 0.16)",
      "400": "rgba(0, 0, 0, 0.24)",
      "500": "rgba(0, 0, 0, 0.36)",
      "600": "rgba(0, 0, 0, 0.48)",
      "700": "rgba(0, 0, 0, 0.64)",
      "800": "rgba(0, 0, 0, 0.80)",
      "900": "rgba(0, 0, 0, 0.92)",
    },
    gray: {
      "50": "#F7FAFC",
      "100": "#EDF2F7",
      "200": "#E2E8F0",
      "300": "#CBD5E0",
      "400": "#A0AEC0",
      "500": "#718096",
      "600": "#4A5568",
      "700": "#2D3748",
      "800": "#1A202C",
      "900": "#171923",
    },
    red: {
      "500": "#D12100",
    },
    orange: {
      "50": "#FFFAF0",
      "100": "#FEEBC8",
      "200": "#FBD38D",
      "300": "#F6AD55",
      "400": "#ED8936",
      "500": "#DD6B20",
      "600": "#C05621",
      "700": "#9C4221",
      "800": "#7B341E",
      "900": "#652B19",
    },
    yellow: {
      "50": "#FFFFF0",
      "100": "#FEFCBF",
      "200": "#FAF089",
      "300": "#F6E05E",
      "400": "#ECC94B",
      "500": "#D69E2E",
      "600": "#B7791F",
      "700": "#975A16",
      "800": "#744210",
      "900": "#5F370E",
    },
    green: {
      "500": "#398E40",
    },
    teal: {
      "50": "#E6FFFA",
      "100": "#B2F5EA",
      "200": "#81E6D9",
      "300": "#4FD1C5",
      "400": "#38B2AC",
      "500": "#319795",
      "600": "#2C7A7B",
      "700": "#285E61",
      "800": "#234E52",
      "900": "#1D4044",
    },
    blue: {
      "50": "#ebf8ff",
      "100": "#bee3f8",
      "200": "#90cdf4",
      "300": "#63b3ed",
      "400": "#4299e1",
      "500": "#3182ce",
      "600": "#2b6cb0",
      "700": "#2c5282",
      "800": "#2a4365",
      "900": "#1A365D",
    },
    cyan: {
      "50": "#EDFDFD",
      "100": "#C4F1F9",
      "200": "#9DECF9",
      "300": "#76E4F7",
      "400": "#0BC5EA",
      "500": "#00B5D8",
      "600": "#00A3C4",
      "700": "#0987A0",
      "800": "#086F83",
      "900": "#065666",
    },
    purple: {
      "50": "#FAF5FF",
      "100": "#E9D8FD",
      "200": "#D6BCFA",
      "300": "#B794F4",
      "400": "#9F7AEA",
      "500": "#805AD5",
      "600": "#6B46C1",
      "700": "#553C9A",
      "800": "#44337A",
      "900": "#322659",
    },
    pink: {
      "50": "#FFF5F7",
      "100": "#FED7E2",
      "200": "#FBB6CE",
      "300": "#F687B3",
      "400": "#ED64A6",
      "500": "#D53F8C",
      "600": "#B83280",
      "700": "#97266D",
      "800": "#702459",
      "900": "#521B41",
    },
    linkedin: {
      "50": "#E8F4F9",
      "100": "#CFEDFB",
      "200": "#9BDAF3",
      "300": "#68C7EC",
      "400": "#34B3E4",
      "500": "#00A0DC",
      "600": "#008CC9",
      "700": "#0077B5",
      "800": "#005E93",
      "900": "#004471",
    },
    facebook: {
      "50": "#E8F4F9",
      "100": "#D9DEE9",
      "200": "#B7C2DA",
      "300": "#6482C0",
      "400": "#4267B2",
      "500": "#385898",
      "600": "#314E89",
      "700": "#29487D",
      "800": "#223B67",
      "900": "#1E355B",
    },
    messenger: {
      "50": "#D0E6FF",
      "100": "#B9DAFF",
      "200": "#A2CDFF",
      "300": "#7AB8FF",
      "400": "#2E90FF",
      "500": "#0078FF",
      "600": "#0063D1",
      "700": "#0052AC",
      "800": "#003C7E",
      "900": "#002C5C",
    },
    whatsapp: {
      "50": "#dffeec",
      "100": "#b9f5d0",
      "200": "#90edb3",
      "300": "#65e495",
      "400": "#3cdd78",
      "500": "#22c35e",
      "600": "#179848",
      "700": "#0c6c33",
      "800": "#01421c",
      "900": "#001803",
    },
    twitter: {
      "50": "#E5F4FD",
      "100": "#C8E9FB",
      "200": "#A8DCFA",
      "300": "#83CDF7",
      "400": "#57BBF5",
      "500": "#1DA1F2",
      "600": "#1A94DA",
      "700": "#1681BF",
      "800": "#136B9E",
      "900": "#0D4D71",
    },
    telegram: {
      "50": "#E3F2F9",
      "100": "#C5E4F3",
      "200": "#A2D4EC",
      "300": "#7AC1E4",
      "400": "#47A9DA",
      "500": "#0088CC",
      "600": "#007AB8",
      "700": "#006BA1",
      "800": "#005885",
      "900": "#003F5E",
    },
    light: {
      white: "#FFFFFF",
      green: {
        "500": "#398E40",
      },
      red: {
        "500": "#D12100",
      },
      whiteAlpha: {
        "10": "#121212",
        "50": "#141414",
        "100": "#1E1E1E",
        "200": "#323232",
        "300": "#4B4B4B",
        "400": "#6E6E6E",
        "500": "#848484",
        "600": "#D3D3D3",
        "700": "#DBDBDB",
        "800": "#F2F2F2",
        "900": "#F8F8F8",
        "950": "#EEEEEE",
        "999": "#EFEFEF",
      },

      brand: {
        "10": "#121212",
        "50": "#141414",
        "100": "#1E1E1E",
        "200": "#323232",
        "300": "#4B4B4B",
        "400": "#6E6E6E",
        "500": "#848484",
        "600": "#D3D3D3",
        "700": "#DBDBDB",
        "800": "#F2F2F2",
        "900": "#F8F8F8",
        "950": "#EEEEEE",
        "999": "#EFEFEF",
      },
      primaryColor: "#398E40",
      navbarBg: "#FFFFFF",
      navbarTextColor: "#121212",
      navbarIconBg: "#E6E6E6",
      menuBg: "#111620",
      menuActiveTextColor: "#FFFFFF",
      menuTextColor: "#576279",
      menuIconColor: "#576279",
      pageBg: "#F8F8F8",
      componentBg: "#FFFFFF",
      componentBorder: "#EEEEEE",
      inputText: "#323232",
      contentText: "#323232",
      tooltipBg: "#4B4B4B",
      tooltipTextColor: "#FFFFFF",
      boxShadow: "#00000029",
      calendarSelectedSpan: "#CFE9BE",
      errorText: "#D12100",
      tableRowBorder: "#E2E2E2",
      subTableRowBorder: "#CECECE",
      tagBg: "#EFEFEF",
      tableResizerBg: "#e3e3e3",
      tableResizingActiveBg: "#6E6E6E",
      tableDragOverBg: "#e5efff",
      selectedTag: "#15651C",
    },
    dark: {
      white: "#FFFFFF",
      green: {
        "500": "#398E40",
      },
      red: {
        "500": "#D12100",
      },
      whiteAlpha: {
        "10": "#121212",
        "50": "#141414",
        "100": "#1E1E1E",
        "200": "#323232",
        "300": "#4B4B4B",
        "400": "#6E6E6E",
        "500": "#848484",
        "600": "#D3D3D3",
        "700": "#DBDBDB",
        "800": "#F2F2F2",
        "900": "#F8F8F8",
        "950": "#EEEEEE",
        "999": "#EFEFEF",
      },

      brand: {
        "10": "#EFEFEF",
        "50": "#EEEEEE",
        "100": "#F8F8F8",
        "200": "#F2F2F2",
        "300": "#DBDBDB",
        "400": "#D3D3D3",
        "500": "#848484",
        "600": "#6E6E6E",
        "700": "#4B4B4B",
        "800": "#323232",
        "900": "#1E1E1E",
        "950": "#141414",
        "999": "#121212",
      },
      primaryColor: "#398E40",
      navbarBg: "#1E1E1E",
      navbarTextColor: "#EFEFEF",
      navbarIconBg: "#141414",
      menuBg: "#1E1E1E",
      menuActiveTextColor: "#FFFFFF",
      menuTextColor: "#6E6E6E",
      menuIconColor: "#6E6E6E",
      pageBg: "#121212",
      inputText: "#939393",
      contentText: "#6E6E6E",
      componentBg: "#1E1E1E",
      componentBorder: "#323232",
      tooltipBg: "#F8F8F8",
      tooltipTextColor: "#1E1E1E",
      boxShadow: "#00000059",
      calendarSelectedSpan: "#122719",
      errorText: "#ff593a",
      tableRowBorder: "#323232",
      subTableRowBorder: "#323232",
      tagBg: "#323232",
      tableResizerBg: "#424242",
      tableResizingActiveBg: "#4299e199",
      tableDragOverBg: "#1f2223",
      selectedTag: "#EEEEEE",
    },
    darkPrimary: {
      "50": "#5db264",
      "100": "#58ad5f",
      "200": "#53a85a",
      "300": "#4ea355",
      "400": "#499e50",
      "500": "#44994b",
      "600": "#3f9446",
      "700": "#3a8f41",
      "800": "#358a3c",
    },
    lightPrimary: {
      "50": "#5db264",
      "100": "#58ad5f",
      "200": "#53a85a",
      "300": "#4ea355",
      "400": "#499e50",
      "500": "#44994b",
      "600": "#3f9446",
      "700": "#3a8f41",
      "800": "#358a3c",
    },
  },
  fonts: {
    body: "Roboto",
    heading: "Roboto",
    mono: "Roboto",
  },
  fontSizes: {
    xs: "12px",
    sm: "13px",
    md: "13px",
    lg: "16px",
    xl: "18px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
  },
  zIndices: {
    tooltip: 9999,
    modal: 99991,
    overlay: 99992,
  },
  styles: {
    global: (props: Dict<any> | StyleFunctionProps) => ({
      "html, body": {
        fontSize: "md",
        bgColor: mode("light.pageBg", "dark.pageBg")(props),
        height: "auto",
        overflow: "visible",
        lineHeight: 1.5,
        margin: 0,
      },
      "*, ::before, ::after": {
        boxSizing: "border-box",
        borderWidth: "0",
        borderStyle: "solid",
        // fontFamily: 'Roboto, sanserif',
      },
      "input:focus, textarea:focus, input:focus-visible, textarea:focus-visible, .chakra-input:focus-visible":
        {
          boxShadow: "none !important",
          borderColor: mode("light.brand.300", "dark.brand.300")(props),
        },
      a: {
        textDecoration: "none",
        color: "inherit",
      },
      "button  > svg": {
        color: mode("light.brand.300", "dark.brand.300")(props),
      },
      ".popover-text > ul": {
        "list-style-type": "none",
        padding: 0,
        margin: 0,
      },
      ".popover-text > li": {
        "margin-bottom": "6px",
      },
      "#datePickerMonth, #datePickerYear": {
        color: "brand.10",
      },
      ".card": {
        "border-radius": "4px",
        "background-color": props.theme.colors[props.colorMode].componentBg,
        border: `1px solid ${
          props.theme.colors[props.colorMode].componentBorder
        }`,
      },
      ".white-space-break": {
        "white-space": "break-spaces",
      },
      ".rdrStaticRangeLabel, .rdrDayNumber, .rdrWeekDay, .rdrMonthName": {
        fontSize: "md",
      },
      "::placeholder": {
        color:  props.theme.colors[props.colorMode].brand['500']
      }
    }),
  },
  font: "Roboto",
  config: {
    initialColorMode: "light",
  },
};

export const selectStyle = (currentColors: any): any => {
  return {
    control: (styles: any) => {
      return {
        ...styles,
        boxShadow: "none",
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: currentColors.brand["600"],
        minHeight: "35px",
        ":hover": {
          borderWidth: 1,
          borderColor: currentColors.brand["600"],
          cursor: "pointer",
        },
        ":focus": {
          borderWidth: 1,
          borderColor: currentColors.brand["600"],
        },
      };
    },
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "default",
        fontSize: "12px",
        ":active": {
          ...styles[":active"],
          backgroundColor: currentColors.brand["600"],
        },
        ":hover": {
          ...styles[":active"],
          backgroundColor: currentColors.brand["800"],
          cursor: "pointer",
        },
        backgroundColor: isSelected ? currentColors.brand["800"] : "#FFF",
        color: currentColors.brand["100"],
      };
    },
    input: (styles: any) => ({
      ...styles,
      width: "200px",
      outline: "none",
      border: "none",
      _placeholder: {
        color: currentColors.brand["500"],
      },
    }),
    placeholder: (styles: any) => ({ ...styles }),
    singleValue: (styles: any, { data }: any) => ({ ...styles }),
    dropdownIndicator: (styles: any) => ({ ...styles, padding: "4px" }),
    clearIndicator: (styles: any) => ({ ...styles, padding: "4px" }),
  };
};
