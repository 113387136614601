import { createStandaloneToast } from "@chakra-ui/react";
import { theme } from "../theme/theme";

const { toast } = createStandaloneToast({ theme });

export const fetchAPI = (url: URL | string, options: RequestInit) =>
  fetch(url, options)
    .then((res) => res)
    .catch((error) => {
      console.error(error);
      // toast({
      //   title: "An error occurred.",
      //   description: "Please try after sometime.",
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      // });
      throw error;
    });
