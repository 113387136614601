import {
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { MdOutlineSearch } from "react-icons/md";

import { useCurrentColors } from "../../hooks/UseCurrentColors";

interface IAutoExpandSearch {
  placeholder?: string;
  tooltip?: string;
  onSearch: (text: string) => void;
}

export const AutoExpandSearch: React.FC<IAutoExpandSearch> = ({
  placeholder = "Search File",
  tooltip = "Search File",
  onSearch,
}: IAutoExpandSearch) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef<HTMLInputElement | null>();
  const [searchText, setSearchText] = useState<string>("");
  const currentColors = useCurrentColors();

  const handleKeyPress = useCallback(
    (e: any) => {
      if (e.key === "Enter") {
        onSearch(searchText);
      }
    },
    [onSearch, searchText]
  );

  const handleChange = useCallback(
    (event: any) => {
      const text = (event.target as HTMLInputElement)?.value || "";
      setSearchText(text);
      if (!text) {
        onSearch(text);
      }
    },
    [onSearch]
  );

  const handleOpenOrSearch = useCallback(() => {
    if (!isOpen) {
      onOpen();
      ref.current?.focus();
    }

    if (isOpen && searchText) { 
      onSearch(searchText);
    }
  }, [isOpen, searchText, onOpen, onSearch]);

  const handleBlur = useCallback(() => {
    if (!searchText) {
      onClose();
    }
  }, [searchText, onClose]);

  return (
    <InputGroup>
      <Input
        ref={(el) => {
          ref.current = el;
        }}
        type="text"
        placeholder={placeholder}
        borderRadius={"20px"}
        borderColor={"transparent"}
        borderWidth={0}
        boxShadow={"none !important"}
        transition={"width 0.1s ease-in-out"}
        cursor="pointer"
        _placeholder={{
          color: isOpen ? currentColors.brand["200"] : "transparent",
        }}
        width={`${isOpen ? 200 : 0}px`}
        padding={isOpen ? "14px 35px 14px 14px" : "0"}
        backgroundColor={currentColors.brand["700"]}
        color={currentColors.brand["200"]}
        onChangeCapture={handleChange}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
      />
      <Tooltip aria-label={tooltip} label={tooltip} hasArrow>
        <InputRightElement
          borderRadius={"20px"}
          _hover={{
            cursor: "pointer",
            backgroundColor: currentColors.brand["700"],
          }}
          onClick={handleOpenOrSearch}
          children={
            <MdOutlineSearch color={currentColors.brand["200"]} size={22} />
          }
        />
      </Tooltip>
    </InputGroup>
  );
};
