import { createHttpLink, gql, useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import React from "react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { Outlet } from "react-router-dom";
import "./App.css";
import CommonContextWrapper from "./context/CommonContext";

export const App: React.FC = () => {
  return (
    <Box width="100%">
      <Box pt="36px" my="16px" mx="32px">
        <CommonContextWrapper>
          <Outlet />
        </CommonContextWrapper>
      </Box>
    </Box>
  );
};

