import { chakra, Input } from "@chakra-ui/react";


export const StyledInput = chakra(Input, {
  baseStyle: (props) => { 
    const borderColor =  props.theme.colors[props.theme.initialColorMode].brand['600']
    return {
      background: "transparent",
      borderColor,
      _hover: {
        borderColor,
      },
      _focus: {
        borderColor,
      },
      cursor: "pointer",
      fontFamily: "inherit",
      margin: "0",
      padding: "0 12px",
      color: "inherit",
    };
  },
  defaultProps: {
    focusBorderColor: "gray.300",
  },
})




