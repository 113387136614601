import { createBrowserRouter, redirect } from "react-router-dom";
import { App } from "../App";
import { appConfig } from "../config";
import { Upload } from "../pages/Upload";
import { UploadedDocuments } from "../pages/UploadedDocuments";

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      children: [
        {
          index: true,
          element: <UploadedDocuments />,
        },
        {
          path: "upload",
          element: <Upload />,
        },
        {
          path: "documents",
          element: <UploadedDocuments />,
        },
      ],
    },
  ], {
    basename: appConfig.basePath
  }
);
