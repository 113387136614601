import { useColorMode, useTheme } from '@chakra-ui/react';

export function useCurrentColors() {
    const { colorMode } = useColorMode();
    const colors = useTheme().colors;
    return {
        ...colors,
        ...colors[colorMode],
    };
}
export function useLightThemeColors() {
    return useTheme().colors['light'];
}
