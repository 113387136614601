import { Dictionary } from "lodash";

export const BASE_URL = process.env.REACT_APP_BACKEND_API;
export const ENVIRONMENTS = process.env.REACT_APP_ENVIRONMENTS;

export const ROLE = {
  superAdmin: "Superadmin",
  partnerAdmin: "Partneradmin",
};

export const PARSER_PARAMETERS = {
  USE_OCR: "USE_OCR",
  USE_ABBYY_FOR_OCR:"USE_ABBYY_FOR_OCR",
  RUN_PDF_REPAIR: "RUN_PDF_REPAIR",
  EUROPEAN_NUMBER_FORMAT:"EUROPEAN_NUMBER_FORMAT"
};

export interface IDocumentParams {
  bank?: string;
  password?: string;
  parserParameters?: string;
  pageRange?: string;
};

export interface IDocument {
  file: File;
  params: IDocumentParams;
  status: {
    error?: boolean;
    success?: boolean;
    message?: string;
    inprogress?: boolean;
  };
  key: number
};

export const PAGE_SIZE = 30;

export const MAX_FILE_SIZE = 50 ; // in MB
export const MB_TO_BYTE_MULTIPLIER = 1000000 ; 
export const MAX_NUMBER_OF_JOB_OPERATIONS = 10;

export const DATA_STORAGE_URL_BY_TOKEN_ISSUER = (()=>{
  const env_dict = ENVIRONMENTS? JSON.parse(ENVIRONMENTS):{};
  let ds_dict:Dictionary<string> = {}
  for (let env in env_dict) {
    let env_val = env_dict[env];
    let api_url = null;
    let cognito_pool = null;
    let storage_url = null;
    for (let prop in env_val) {
        if(prop == "api_url")
          api_url = env_val[prop];
        if(prop == "cognito_pool")
          cognito_pool = env_val[prop];
        if(prop == "storage_url")
          storage_url = env_val[prop];
    }
    if (storage_url){
      if(api_url)
        ds_dict[api_url] = storage_url;
      if(cognito_pool)
        ds_dict[cognito_pool] = storage_url;
    }
  }
  return ds_dict;
})();