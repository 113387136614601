import {
  Button,
  Code,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import React, { useCallback, useState } from "react";

import moment from "moment";

import { downloadLogs, getFileName, viewLogs } from "../../api/downloadFile";
import { ROLE } from "../../common/constant";
import { getParsedToken } from "../../hooks/token";
import { useCurrentColors } from "../../hooks/UseCurrentColors";
import { LogFile } from "../../icons/LogFile";
import { IFile } from "../DocumentTable/data";
import { FaCopy, FaFileDownload } from "react-icons/fa";
import Highlight, { defaultProps } from "prism-react-renderer";
import prismTheme from "prism-react-renderer/themes/vsDark";

export const Logs: React.FC<{ info: CellContext<IFile, unknown> }> = ({
  info,
}) => {
  const currentColors = useCurrentColors();
  const token = getParsedToken();
  const [display, setDisplay] = useState(false);
  const [fileContent, setFileContent] = useState<string>();
  const [fileName, setFileName] = useState<any>();
  const toast = useToast();

  const taskId = React.useMemo(() => {
    const history = [...info?.row?.original?.historySet?.edges];
    if (history?.length > 1) {
      return history?.sort((itemA: any, itemB: any) =>
        moment(itemA?.node?.createdAt).diff(moment(itemB?.node?.createdAt))
      )[history.length -1]?.node?.taskId;
    } else if (history?.length === 1) {
      return history.at(0)?.node?.taskId;
    }
    return info?.row?.original?.taskId;
  }, [info]);

  const copyLogHandler = useCallback(() => {
    if (fileContent) {
      navigator.clipboard.writeText(fileContent).then(
        () => {
          toast({
            title: `Logs copied to clipboard successfully.`,
            status: "success",
            duration: 3000,
            variant: "solid",
          });
        },
        (err) => {
          toast({
            title: `Error while coping logs to clipboard.`,
            status: "success",
            duration: 3000,
            variant: "solid",
          });
        }
      );
    }
  }, [fileContent, toast]);

  const downloadLogHandler = useCallback(() => {
    downloadLogs(fileContent, fileName);
  }, [fileContent, fileName]);

  const viewLogHandler = useCallback(async () => {
    try {
      setDisplay(true);
      const response = await viewLogs(taskId);
      const content = await response.text();
      const name = getFileName(response);
      setFileName(name);
      setFileContent(content);
    } catch (error) {
      setDisplay(false);
      setFileContent("");
      setFileName("");
      toast({
        title: `Error while fetching log.`,
        status: "error",
        duration: 3000,
        variant: "solid",
      });
      console.error(error);
    }
  }, [taskId, toast]);

  const handleModelClose = useCallback(() => {
    setDisplay(false);
    setFileContent("");
  }, []);

  return token.role_name === ROLE.superAdmin ? (
    <>
      <Tooltip aria-label={"View Logs"} label="View Logs" hasArrow>
        <IconButton
          aria-label="View Logs"
          icon={
            <LogFile fill={currentColors.brand["300"]} height={20} width={20} />
          }
          background="transparent"
          variant="ghost"
          onClick={viewLogHandler}
        />
      </Tooltip>
      <Modal
        isOpen={display}
        onClose={handleModelClose}
        scrollBehavior={"inside"}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Logs</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {fileContent ? (
              <Highlight
                {...defaultProps}
                code={fileContent}
                language={"bash"}
                theme={prismTheme}
              >
                {({
                  className,
                  style,
                  tokens,
                  getLineProps,
                  getTokenProps,
                }) => (
                  <pre className={className} style={style}>
                    {tokens.map((line, i) => (
                      <div {...getLineProps({ line, key: i })}>
                        {line.map((token, key) => (
                          <span
                            {...getTokenProps({ token, key })}
                            className="white-space-break"
                          />
                        ))}
                      </div>
                    ))}
                  </pre>
                )}
              </Highlight>
            ) : (
              <Flex p={16} alignItems="center" justifyContent="center"> <Spinner /></Flex>
            )}
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <Flex>
              <Tooltip aria-label={"Download"} label="Download" hasArrow>
                <IconButton
                  aria-label="Download"
                  color={currentColors.brand["400"]}
                  icon={<FaFileDownload size="20" color={"currentColor"} />}
                  background="transparent"
                  variant="ghost"
                  isDisabled={!fileContent}
                  _disabled={{
                    color: currentColors.brand["700"],
                  }}
                  onClick={downloadLogHandler}
                />
              </Tooltip>
              <Tooltip
                aria-label={"Copy to clipboard"}
                label="Copy to clipboard"
                hasArrow
              >
                <IconButton
                  aria-label="Copy to clipboard"
                  color={currentColors.brand["400"]}
                  icon={<FaCopy size="20" color={"currentColor"} />}
                  _disabled={{
                    color: currentColors.brand["700"],
                  }}
                  background="transparent"
                  variant="ghost"
                  isDisabled={!fileContent}
                  onClick={copyLogHandler}
                />
              </Tooltip>
            </Flex>
            <Button variant="ghost" onClick={handleModelClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : null;
};
