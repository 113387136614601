import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import React, {
  HTMLProps,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import { FaFileDownload, FaFilePdf } from "react-icons/fa";
import { VscDebugRerun } from "react-icons/vsc";

import moment from "moment";

import { useLazyQuery, useMutation } from "@apollo/client";
import { range } from "lodash";
import {
  MdDoDisturbAlt,
  MdHourglassEmpty,
  MdOutlineCheckCircleOutline,
  MdOutlineHighlightAlt,
  MdPendingActions,
} from "react-icons/md";
import { downloadFile, FileType } from "../../api/downloadFile";
import { PAGE_SIZE } from "../../common/constant";
import { useCurrentColors } from "../../hooks/UseCurrentColors";
import { RE_PROCESS_STATEMENT } from "../../mutations/reProcessStatement";
import { FILE_QUERY } from "../../queries/file";
import { Logs } from "../Logs";
import { IFile } from "./data";

const columnHelper = createColumnHelper<IFile>();

export const StatusMapping: Record<string, string> = {
  EX_TBP: "To Be Processed",
  EX_PRO: "Extraction Processing",
  EX_SUC: "Extraction Successful",
  EX_FAI: "Extraction Failed",
  EX_NOT: "Extraction Empty",
};

export const StatusIconMapping: Record<string, any> = {
  EX_TBP: <MdPendingActions size={18} color="gray" />,
  EX_PRO: <MdHourglassEmpty size={18} color="blue" />,
  EX_SUC: <MdOutlineCheckCircleOutline size={18} color="green" />,
  EX_FAI: <MdDoDisturbAlt size={18} color="red" />,
  EX_NOT: <MdOutlineHighlightAlt size={18} color="black" />,
};

export const PageMapping: Array<Record<string, string | number | null>> = [
  {
    label: "1-5",
    start: 1,
    end: 5,
  },
  {
    label: "5-10",
    start: 5,
    end: 10,
  },
  {
    label: "10-20",
    start: 10,
    end: 20,
  },
  {
    label: "20+",
    start: 20,
    end: null,
  },
];

const Actions: React.FC<{ info: CellContext<IFile, unknown> }> = ({ info }) => {
  const currentColors = useCurrentColors();
  const toast = useToast();
  const [reProcessStatement] = useMutation(RE_PROCESS_STATEMENT);
  const [fetchFile] = useLazyQuery(FILE_QUERY);

  const file = React.useMemo(() => info?.row.original || "", [info]);
  const fileId = React.useMemo(() => info?.row.original?.fileId || "", [info]);

  const pdfHandler = React.useCallback(
    async (openFile: boolean = false) => {
      try {
        await downloadFile(fileId, FileType.INPUT_PDF, openFile);
      } catch (error) {
        console.error(error);
      }
    },
    [fileId]
  );

  const pdfPreviewHandler = React.useCallback(() => {
    pdfHandler(true);
  }, [fileId]);

  const pdfDownloadHandler = React.useCallback(() => {
    pdfHandler();
  }, [fileId]);

  const extractedOutputDownloadHandler = React.useCallback(async () => {
    try {
      await downloadFile(fileId, FileType.OUTPUT_EXCEL);
    } catch (error) {
      console.error(error);
    }
  }, [fileId]);

  const ulDownloadHandler = React.useCallback(async () => {
    try {
      await downloadFile(fileId, FileType.CANOPY_UL);
    } catch (error) {
      console.error(error);
    }
  }, [fileId]);

  const handleReProcessStatement = React.useCallback(() => {
    if (file) {
      reProcessStatement({
        variables: {
          bank: file.bankCode,
          fileUuid: file.fileId,
          subtype: file.subtype,
        },
      })
        .then((data) => {
          const res = data.data?.reProcessStatement;
          if (res?.success) {
            toast({
              title: `Extraction for ${file.filename} is restarted.`,
              status: "success",
              duration: 3000,
              variant: "solid",
            });
            fetchFile({
              variables: {
                filesId: file.id,
              },
            });
          } else {
            toast({
              title: `Extraction for ${file.filename} failed to restarted.`,
              status: "error",
              duration: 3000,
            });
          }
        })
        .catch((error) => {
          toast({
            title: `Extraction for ${file.filename} failed to restarted.`,
            status: "error",
            duration: 3000,
          });
        });
    }
  }, [file, reProcessStatement, fetchFile, toast]);

  const isParsedSuccessfully = info?.row?.original.parsingStatus === "EX_SUC";

  return (
    <Flex fontSize="18px" gap="6px" position={"relative"}>
      <Logs info={info} />
      <Tooltip aria-label={"View PDF"} label="View PDF" hasArrow>
        <IconButton
          aria-label="View PDF"
          icon={<FaFilePdf size="20" />}
          background="transparent"
          variant="ghost"
          onClick={pdfPreviewHandler}
        />
      </Tooltip>
      <Menu>
        <Tooltip label="Download" hasArrow>
          <MenuButton as={IconButton} background="transparent" variant="ghost">
            <IconButton
              aria-label="Download"
              icon={<FaFileDownload size="20" />}
              background="transparent"
              variant="ghost"
            />
          </MenuButton>
        </Tooltip>
        <MenuList fontSize={"md"}>
          <MenuItem value="downloadPDF" onClick={pdfDownloadHandler}>
            Download PDF
          </MenuItem>
          <MenuItem
            value="downloadOutput"
            onClick={extractedOutputDownloadHandler}
            isDisabled={!isParsedSuccessfully}
          >
            Download Extracted Output
          </MenuItem>
          <MenuItem
            value="downloadUL"
            onClick={ulDownloadHandler}
            isDisabled={!isParsedSuccessfully}
          >
            Download UL 2.0
          </MenuItem>
        </MenuList>
      </Menu>
      <Tooltip label="Restart" hasArrow>
        <IconButton
          aria-label="Restart"
          icon={<VscDebugRerun size="20" />}
          background="transparent"
          variant="ghost"
          onClick={handleReProcessStatement}
          isDisabled={
            file.parsingStatus === "EX_PRO" || file.parsingStatus === "EX_TBP"
          }
        />
      </Tooltip>
    </Flex>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  checked,
  onChange,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const { colorMode } = useColorMode();
  return (
    <Checkbox
      colorScheme={`${colorMode}Primary`}
      isChecked={checked}
      isIndeterminate={indeterminate}
      onChange={onChange}
    />
  );
}

const columns = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      </div>
    ),
  }),
  columnHelper.accessor("filename", {
    header: () => "File Name",
    cell: (info) => <Text width={200}>{info.getValue()}</Text>,
  }),
  columnHelper.accessor("pages", {
    header: () => "Pages",
    cell: (info) => info.getValue() || "-",
  }),
  columnHelper.accessor("bank", {
    header: () => "Bank",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("parsingStatus", {
    header: () => "Status",
    cell: (info) => {
      return (
        <Flex alignItems={"center"}>
          <Box marginRight={1}>{StatusIconMapping[info.getValue()]}</Box>
          <Text>{StatusMapping[info.getValue()] || info.getValue()}</Text>
        </Flex>
      );
    },
  }),
  columnHelper.accessor("createdAt", {
    header: () => "Created At",
    cell: (info) => moment(info?.getValue()).format("DD/MM/YYYY HH:mm"),
  }),
  columnHelper.accessor("lastModified", {
    header: () => "Last Modified",
    cell: (info) => moment(info?.getValue()).format("DD/MM/YYYY HH:mm"),
  }),
  columnHelper.accessor("createdBy", {
    header: () => "Created By",
    cell: (info) => info.getValue(),
  }),
  columnHelper.display({
    id: "actions",
    header: () => "Actions",
    cell: (info) => <Actions info={info} />,
  }),
];

export const DocumentTable = React.forwardRef(
  (
    {
      data,
      isLoading,
      total,
      hasMore,
      currentPage,
      onRowSelection,
      onPageChange,
    }: {
      data: IFile[] | null;
      total: number;
      isLoading: boolean;
      hasMore: boolean;
      currentPage?: number;
      onRowSelection: (selectedRows: any[]) => void;
      onPageChange: (pageIndex: number) => void;
    },
    ref: any
  ) => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState({});
    const [pageIndex, setPageIndex] = useState(currentPage || 0);

    const currentColors = useCurrentColors();
    const pageCount = Math.ceil(total / PAGE_SIZE);

    const table = useReactTable({
      data: data || [],
      columns,
      state: {
        sorting,
        rowSelection,
      },
      onRowSelectionChange: setRowSelection,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
    });

    useImperativeHandle(
      ref,
      () => ({
        resetSelected() {
          table.resetRowSelection();
        },
      }),
      [table]
    );

    const canPreviousPage = pageIndex > 0;
    const canNextPage = pageIndex + 1 < pageCount;

    const handlePageChange = useCallback(
      (i: number) => {
        setPageIndex(i);
        onPageChange(i);
      },
      [onPageChange]
    );

    const selectedRows = React.useMemo(
      () => Object.keys(rowSelection).map((item) => data && data[+item]),
      [rowSelection]
    );

    React.useEffect(() => {
      onRowSelection && onRowSelection(selectedRows);
    }, [selectedRows]);

    React.useEffect(() => {
      if (pageIndex !== currentPage) {
        setPageIndex(currentPage || 0);
      }
    }, [currentPage]);

    return (
      <Box
        bg={currentColors.componentBg}
        p="20px"
        position="relative"
        border={`1px solid ${currentColors.componentBorder}`}
        minH="100px"
      >
        {data && data?.length > 0 ? (
          <>
            <Table fontSize="md">
              <Thead mt={20} zIndex="docked" bg={currentColors.componentBg}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        fontSize={13}
                        key={header.id}
                        textTransform="initial"
                        fontWeight="900"
                      >
                        {header.isPlaceholder ? null : (
                          <Flex
                            alignItems="center"
                            gap="4px"
                            letterSpacing={0}
                            _hover={{
                              cursor: header.column.getCanSort()
                                ? "pointer"
                                : "default",
                            }}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            <Box fontSize="16px" width="16px">
                              {{
                                asc: <AiOutlineSortAscending />,
                                desc: <AiOutlineSortDescending />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </Box>
                          </Flex>
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={
                            cell.column.id === "filename" ? 13 : "inherit"
                          }
                          fontWeight={
                            cell.column.id === "filename" ? "900" : "inherit"
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Flex alignItems="center" justifyContent="flex-end" mt={4}>
              <IconButton
                background="transparent"
                cursor="pointer"
                aria-label="previous"
                isDisabled={!canPreviousPage}
                variant="ghost"
                icon={<BiChevronLeft />}
                mx="8px"
                width="13px"
                height="13px"
                size="xs"
                onClick={() => handlePageChange(pageIndex - 1)}
              />

              {canPreviousPage && (
                <Button
                  name="previous-page"
                  key={pageIndex}
                  mx="4px"
                  fontSize="13px"
                  lineHeight="18px"
                  variant="ghost"
                  fontWeight="normal"
                  width="25px"
                  height="25px"
                  size="xs"
                  onClick={() => {
                    handlePageChange(pageIndex - 1);
                  }}
                >
                  {pageIndex}
                </Button>
              )}

              <Button
                name="previous-page"
                key={pageIndex + 1}
                mx="4px"
                fontSize="13px"
                lineHeight="18px"
                fontWeight="bold"
                variant="ghost"
                backgroundColor={currentColors.primaryColor}
                _hover={{
                  backgroundColor: currentColors.primaryColor,
                }}
                color={currentColors.white}
                width="25px"
                minWidth="25px"
                height="25px"
                size="xs"
                onClick={() => {
                  handlePageChange(pageIndex);
                }}
              >
                {pageIndex + 1}
              </Button>

              {canNextPage && (
                <Button
                  name="next-page"
                  key={pageIndex + 2}
                  mx="4px"
                  fontSize="13px"
                  lineHeight="18px"
                  fontWeight="normal"
                  variant="ghost"
                  width="25px"
                  minWidth="25px"
                  height="25px"
                  size="xs"
                  _focus={{ outline: "none" }}
                  onClick={() => {
                    handlePageChange(pageIndex + 1);
                  }}
                >
                  {pageIndex + 2}
                </Button>
              )}
              
              {canNextPage && !canPreviousPage && pageCount >= pageIndex + 3 && (
                <Button
                  mx="4px"
                  fontSize="13px"
                  lineHeight="18px"
                  fontWeight="normal"
                  variant="ghost"
                  _focus={{ outline: "none" }}
                  width="25px"
                  minWidth="25px"
                  height="25px"
                  size="xs"
                  onClick={() => {
                    handlePageChange(pageIndex + 2);
                  }}
                >
                  {pageIndex + 3}
                </Button>
              )}
              <IconButton
                background="transparent"
                cursor="pointer"
                aria-label="next"
                isDisabled={!canNextPage}
                variant="ghost"
                icon={<BiChevronRight />}
                mx="8px"
                width="13px"
                height="13px"
                size="xs"
                onClick={() => handlePageChange(pageIndex + 1)}
              />

              <Flex alignItems="center">
                <Text
                  flex="1 0 auto"
                  fontSize="13px"
                  lineHeight="18px"
                  mr="10px"
                >
                  Jump to
                </Text>
                <Select
                  value={pageIndex + 1}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    handlePageChange(+e.target.value - 1);
                  }}
                  maxWidth="80px"
                  height="30px"
                  size="sm"
                >
                  {range(pageCount).map((option) => (
                    <option key={option} value={option + 1}>
                      {option + 1}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Flex>
          </>
        ) : (
          !isLoading && <Text textAlign={"center"}>No Result Found</Text>
        )}

        {isLoading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            position="absolute"
            background="rgb(255 255 255 / 50%)"
            top={0}
            bottom={0}
            left={0}
            right={0}
            my={6}
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
        {!isLoading && !data && (
          <Text style={{ textAlign: "center" }}>No Files Found</Text>
        )}
      </Box>
    );
  }
);
