import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useRef } from "react";
import { BiRefresh } from "react-icons/bi";
import { FaFilter } from "react-icons/fa";
import {
  MdFileUpload,
  MdKeyboardArrowDown,
  MdOutlineSearch,
} from "react-icons/md";

import { Link } from "react-router-dom";
import { downloadBulkFile, FileType } from "../api/downloadFile";

import { endOfDay, isSameDay, startOfDay } from "date-fns";
import {
  CanopyDateRangePicker,
  ICanopyDateRange,
} from "../components/DateRangePicker";
import { DocumentTable, StatusMapping } from "../components/DocumentTable";
import { Filter } from "../components/Filter";
import { useCurrentColors } from "../hooks/UseCurrentColors";
import { RE_PROCESS_STATEMENT } from "../mutations/reProcessStatement";
import { FILE_QUERY } from "../queries/file";
import { FILES_QUERY } from "../queries/files";
import { useData } from "../hooks/data";
import { PAGE_SIZE, MAX_NUMBER_OF_JOB_OPERATIONS } from "../common/constant";
import { AutoExpandSearch } from "../components/AutoExpandSearch";

const FilterTags = ({
  filters,
  onChange,
}: {
  filters: Record<string, any>;
  onChange?: (variable: any) => void;
}) => {
  const { banks } = useData();
  const selectedBank = banks?.find(
    (bank) => bank.bank_code === filters?.bankCode
  );

  return (
    <Flex>
      {selectedBank && (
        <Tag
          size={"lg"}
          key={selectedBank?.bank_name}
          borderRadius="full"
          variant="solid"
          mr={2}
          onClick={() => onChange && onChange({ ...filters, bankCode: null })}
        >
          <TagLabel>{selectedBank?.bank_name}</TagLabel>
          <TagCloseButton />
        </Tag>
      )}
      {filters?.fileStatus && (
        <Tag
          size={"lg"}
          key={filters?.fileStatus}
          borderRadius="full"
          variant="solid"
          mr={2}
          onClick={() => onChange && onChange({ ...filters, fileStatus: null })}
        >
          <TagLabel>{StatusMapping[filters?.fileStatus]}</TagLabel>
          <TagCloseButton />
        </Tag>
      )}
    </Flex>
  );
};

export const UploadedDocuments: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentColors = useCurrentColors();
  const tableRef = useRef<any>(null);

  const { banks, bankMapping } = useData();

  const [isBulkActionOpen] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<Record<string, any>[]>([]);
  const [totalResult, setTotalResult] = React.useState<number>(0);
  const [searchText, setSearchText] = React.useState<string>("");
  const [variables, setVariables] = React.useState<Record<string, any>>({});
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [dateRange, setDateRange] = React.useState<ICanopyDateRange>({
    startDate: undefined,
    endDate: undefined,
    key: "selection",
  });
  const toast = useToast();
  const toast_error_id = 'error-toast';
  const [fetchFile] = useLazyQuery(FILE_QUERY);
  const [reProcessStatement] = useMutation(RE_PROCESS_STATEMENT);

  const [fetchFiles, { loading, error, data }] = useLazyQuery(FILES_QUERY, {
    pollInterval: 10000,
  });

  const getCreatedDateVariables = React.useCallback(() => {
    if (dateRange?.startDate && dateRange?.endDate) {
      return {
        createdAtGte: startOfDay(dateRange?.startDate),
        createdAtLte: isSameDay(dateRange?.endDate, new Date())
          ? new Date()
          : endOfDay(dateRange?.endDate),
      };
    } else {
      return {};
    }
  }, [dateRange]);

  React.useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  React.useEffect(() => {
    if (dateRange?.startDate && dateRange?.endDate) {
      const vars = {
        offset: 0,
        ...variables,
        ...getCreatedDateVariables(),
      };
      setCurrentPage(0);
      setVariables(vars);
      fetchFiles({ variables: vars });
    } else {
      const { createdAtGte, createdAtLte, ...vars } = variables;
      setCurrentPage(0);
      setVariables(vars);
      fetchFiles({ variables: vars, fetchPolicy: "network-only" });
    }
  }, [dateRange]);

  const selectedFileIds = React.useMemo(() => {
    return selected.map((info) => info?.fileId || "") || [];
  }, [selected]);

  const fetchAgain = React.useCallback(() => {
    fetchFiles({ variables, fetchPolicy: "network-only" });
  }, [fetchFiles, variables]);

  const updateVariable = React.useCallback(
    (newVariables: any) => {
      const vars = {
        ...variables,
        ...newVariables,
        ...getCreatedDateVariables(),
      };
      setCurrentPage(0);
      setVariables(vars);
      fetchFiles({ variables: vars });
    },
    [variables, fetchFiles, getCreatedDateVariables]
  );

  const handleBulkReprocess = React.useCallback(() => {
    let counter = selected.length;
    let successCount = 0;
    let failCount = 0;
    if (counter <= MAX_NUMBER_OF_JOB_OPERATIONS) {
      selected.forEach((file) => {
        reProcessStatement({
          variables: {
            bank: file.bankCode,
            fileUuid: file.fileId,
            subtype: file.subtype,
          },
        })
          .then((data) => {
            counter = counter - 1;
            const res = data.data?.reProcessStatement;
            if (res?.success) {
              successCount = successCount + 1;
              fetchFile({
                variables: {
                  filesId: file.id,
                },
              });
            } else {
              failCount = failCount + 1;
            }
          })
          .finally(() => {
            if (counter === 0) {
              if (successCount) {
                toast({
                  title:
                    successCount === 1
                      ? `Extraction is restarted for 1 file.`
                      : `Extraction is restarted for ${successCount} file(s).`,
                  status: "success",
                  duration: 3000,
                  variant: "solid",
                });
              } else {
                toast({
                  title: `Extraction failed to restart for ${failCount} file(s).`,
                  status: "error",
                  duration: 3000,
                  variant: "solid",
                });
              }
            }
          });
      });
    } else {
      if (!toast.isActive(toast_error_id)) {
        toast({
          id: toast_error_id,
          title: "To many files",
          description: "Cannot restart more than " + MAX_NUMBER_OF_JOB_OPERATIONS + " files at a time. Remove some file(s).",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }, [selected, reProcessStatement, fetchFile, toast]);

  React.useEffect(() => {
    if (!loading && data) {
      setTotalResult(data?.allFiles?.totalCount);
      if (tableRef?.current?.resetSelected) {
        tableRef?.current?.resetSelected();
      }
    }
  }, [data, loading]);

  const onResetFilter = useCallback(() => {
    const fileContains = variables.fileContains;
    const newVariables = fileContains ? { fileContains } : {}
    setVariables(newVariables);
    fetchFiles({ variables: newVariables, fetchPolicy: "network-only" });
  }, [variables, fetchFiles]);


  const handlePageChange = useCallback(
    (index: number) => {
      const offset = index * PAGE_SIZE;
      const vars = {
        ...variables,
        offset,
      };
      setCurrentPage(index);
      setVariables(vars);
      fetchFiles({ variables: { ...vars } });
    },
    [variables, fetchFiles]
  );

  return (
    <>
      <Flex mb={6} mt={6} justifyContent="space-between">
        <Text mr={3} fontSize="24" fontWeight="500">
          Extract Dashboard
        </Text>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text mr={3} fontWeight="bold">
            Created At
          </Text>
          <CanopyDateRangePicker
            dateRange={dateRange}
            onChange={setDateRange}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Flex direction={"column"}>
          <Flex>
            {selected.length > 0 && (
              <Menu>
                <MenuButton
                  isActive={isBulkActionOpen}
                  as={Button}
                  rightIcon={<MdKeyboardArrowDown size={18} />}
                  mr={2}
                >
                  Bulk Action
                </MenuButton>
                <MenuList>
                  <MenuItem
                    value="option1"
                    onClick={() =>
                      downloadBulkFile(selectedFileIds, FileType.INPUT_PDF)
                    }
                  >
                    Download PDF
                  </MenuItem>
                  <MenuItem
                    value="option2"
                    onClick={() =>
                      downloadBulkFile(selectedFileIds, FileType.OUTPUT_EXCEL)
                    }
                  >
                    Download Extracted Output
                  </MenuItem>
                  <MenuItem
                    value="option3"
                    onClick={() =>
                      downloadBulkFile(selectedFileIds, FileType.CANOPY_UL)
                    }
                  >
                    Download UL 2.0
                  </MenuItem>
                  <MenuItem value="option3" onClick={handleBulkReprocess}>
                    Restart
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            {/* <FilterTags
              filters={variables}
              onChange={updateVariable}
            /> */}
          </Flex>
        </Flex>
        <Flex direction={"column"}>
          <Flex gap={2} alignSelf={"flex-end"}>
            <AutoExpandSearch
              onSearch={(value) => {
                updateVariable({
                  ...variables,
                  offset: 0,
                  fileContains: value,
                });
                setSearchText(value);
              }}
            />
            <Tooltip aria-label="Refresh" label="Refresh" hasArrow>
              <IconButton
                color={currentColors.brand["200"]}
                variant="ghost"
                aria-label="Refresh"
                fontSize="24px"
                mr={1}
                onClick={fetchAgain}
                icon={<BiRefresh />}
              />
            </Tooltip>
            <Tooltip aria-label="Filter" label="Filter" hasArrow>
              <IconButton
                color={currentColors.brand["200"]}
                variant="ghost"
                aria-label="Filter"
                fontSize="16px"
                mr={4}
                onClick={onOpen}
                icon={<FaFilter />}
              />
            </Tooltip>
            <Link to={"../upload"}>
              <Button width="150px">
                <MdFileUpload size={18} color="white" />
                Upload
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
      <Box mt={3}>
        {data &&
          !data.allFiles?.edges &&
          !loading &&
          !Object.keys(variables).length ? (
          <Box
            bg={currentColors.componentBg}
            p="20px"
            border={`1px solid ${currentColors.componentBorder}`}
          >
            <Text textAlign="center">
              Did not parse yet? Click Upload to get started.
            </Text>
          </Box>
        ) : (
          <DocumentTable
            ref={tableRef}
            data={
              data?.allFiles?.edges?.map((item: any) => {
                const {
                  id,
                  inputFile,
                  bankCode,
                  mUser,
                  parsingStatus,
                  createdAt,
                  updatedAt,
                  numPagesWithContent,
                  fileUuid,
                  originalFile,
                  ...rest
                } = item?.node;
                const filename = inputFile?.split("/")?.at(-1)?.split("_");
                return {
                  ...rest,
                  id: id,
                  filename: inputFile
                    ? filename?.slice(1, filename.length)?.join("_")
                    : originalFile?.split("/")?.at(-1),
                  fileId: fileUuid,
                  bank: bankMapping[bankCode] || bankCode || "-",
                  bankCode,
                  parsingStatus: parsingStatus,
                  createdBy: mUser?.userId,
                  createdAt: createdAt,
                  lastModified: updatedAt,
                  pages: numPagesWithContent,
                };
              }) || null
            }
            total={totalResult || 0}
            isLoading={loading}
            currentPage={currentPage}
            hasMore={data?.allFiles?.pageInfo?.hasNextPage}
            onRowSelection={(selectedRows) => setSelected(selectedRows)}
            onPageChange={handlePageChange}
          />
        )}
      </Box>

      <Filter
        data={data?.allFiles?.edges || []}
        isOpen={isOpen}
        onClose={onClose}
        filters={variables}
        onChange={updateVariable}
        onReset={onResetFilter}
      />
    </>
  );
};
