import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  Flex,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import { endOfDay, isSameDay, startOfDay } from "date-fns";
import React, { useCallback } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Select from "react-select";
import { ROLE } from "../../common/constant";
import { useData } from "../../hooks/data";
import { getParsedToken } from "../../hooks/token";
import { useCurrentColors } from "../../hooks/UseCurrentColors";
import { ALL_USERS } from "../../queries/allUsers";
import { selectStyle } from "../../theme/theme";
import { CanopyDateRangePicker, ICanopyDateRange } from "../DateRangePicker";
import { PageMapping, StatusMapping } from "../DocumentTable";

export const Filter: React.FC<{
  data: Record<string, any>[];
  isOpen: boolean;
  filters: Record<string, any>;
  onClose: () => void;
  onChange?: (variable: any) => void;
  onReset: () => void;
}> = ({ data, isOpen, filters, onClose, onChange, onReset }) => {
  const { banks } = useData();
  const currentColors = useCurrentColors();
  const [selectedBank, setSelectedBank] = React.useState<any>(null);
  const [selectedStatus, setSelectedStatus] = React.useState<any>(null);
  const [selectedPage, setSelectedPage] = React.useState<any>(null);
  const [selectedCreatedBy, setSelectedCreatedBy] = React.useState<any>(null);
  const [dateRange, setDateRange] = React.useState<ICanopyDateRange>({
    startDate: undefined,
    endDate: undefined,
    key: "selection",
  });
  const token = getParsedToken();

  const { data: users } = useQuery(ALL_USERS);

  const applyHandler = () => {
    onClose();
    onChange &&
      onChange({
        bankCode: selectedBank?.value,
        fileStatus: selectedStatus?.value,
        ...(
         {
            updatedAtGte: dateRange?.startDate && startOfDay(dateRange?.startDate),
            updatedAtLte:  !dateRange?.endDate || isSameDay(dateRange?.endDate, new Date())
              ? new Date()
              : endOfDay(dateRange?.endDate),
          }),
        ...({numPagesWithContentGte: selectedPage?.value?.start,}),
        ...({numPagesWithContentLte: selectedPage?.value?.end}),
        ...({ clientId: selectedCreatedBy?.value }),
      });
  };

  React.useEffect(() => {
    if (selectedBank?.value !== filters?.bankCode) {
      if (!filters?.bankCode) {
        setSelectedBank(null);
      } else {
        const foundBank: any = banks.find((bank) => ({
          label: bank?.bank_name,
          value: bank?.bank_code,
        }));
        setSelectedBank(foundBank);
      }
    }
    if (selectedStatus?.value !== filters?.fileStatus) {
      setSelectedStatus(filters?.fileStatus);
    }
  }, [filters]);

  const handleReset = useCallback(() => {
    setSelectedBank(null);
    setSelectedStatus(null);
    setSelectedPage(null);
    setSelectedCreatedBy(null);
    setDateRange({ ...dateRange, startDate: undefined, endDate: undefined });
    onReset && onReset();
  }, [onReset]);

  return (
    <Drawer isOpen={isOpen} placement="right" size="sm" onClose={onClose}>
      <DrawerContent p={5} pt={6}>
        <Flex p="5px" justify="space-between">
          <Flex>
            <Image
              src={"/icons/icon-72x72.png"}
              maxWidth="100%"
              width="auto"
              height={"30px"}
            />
            <Box
              ml="15px"
              color={currentColors.brand["10"]}
              fontWeight="bold"
              fontSize="20px"
            >
              Filter
            </Box>
          </Flex>
          <Flex>
            <IconButton
              aria-label="remove"
              fontSize={20}
              icon={<AiOutlineCloseCircle />}
              onClick={onClose}
              variant="ghost"
            />
          </Flex>
        </Flex>
        <Divider
          borderColor={currentColors.brand["700"]}
          width="100%"
          my="10px"
        />

        <DrawerBody px="1">
          <Box mb={4}>
            <Select
              name="Bank"
              placeholder="Bank"
              aria-label="Bank"
              isClearable
              styles={selectStyle(currentColors)}
              value={selectedBank}
              onChange={(value) => setSelectedBank(value)}
              options={banks?.map((bank) => ({
                label: bank?.bank_name,
                value: bank?.bank_code,
              }))}
            />
          </Box>
          <Box mb={4}>
            <Select
              name="Status"
              placeholder="Status"
              aria-label="Status"
              isClearable
              styles={selectStyle(currentColors)}
              value={selectedStatus}
              onChange={setSelectedStatus}
              options={Object.keys(StatusMapping).map((status) => ({
                value: status,
                label: StatusMapping[status],
              }))}
            />
          </Box>
          <Box mb={4}>
            <Select
              name="Pages"
              placeholder="Pages"
              aria-label="Pages"
              isClearable
              styles={selectStyle(currentColors)}
              value={selectedPage}
              onChange={setSelectedPage}
              options={PageMapping.map((page) => ({
                value: page,
                label: page.label,
              }))}
            />
          </Box>
          {(token.role_name === ROLE.superAdmin ||
            token.role_name === ROLE.partnerAdmin) &&
            users?.allUsers?.edges && (
              <Box mb={4}>
                <Select
                  name="Created By"
                  placeholder="Created By"
                  aria-label="Created By"
                  isClearable
                  styles={selectStyle(currentColors)}
                  value={selectedCreatedBy}
                  onChange={setSelectedCreatedBy}
                  options={users.allUsers.edges.map((user: any) => ({
                    value: user.node.userId,
                    label: user.node.userId,
                  }))}
                />
              </Box>
            )}
          <Flex mb={4} alignItems="center">
            <Text mr={2}>Last Modified </Text>
            <CanopyDateRangePicker
              dateRange={dateRange}
              onChange={setDateRange}
            />
          </Flex>
        </DrawerBody>

        <DrawerFooter p="0">
          <Flex direction="column" width="100%">
            <Divider
              borderColor={currentColors.brand["700"]}
              width="100%"
              my="15px"
            />
            <Flex>
              <Button
                width="154px"
                fontSize="sm"
                variant="outline"
                mr="12px"
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button width="154px" fontSize="sm" onClick={applyHandler}>
                Apply
              </Button>
            </Flex>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
