import { Box, Button, Flex, Link, Spinner, useToast } from "@chakra-ui/react";
import { cloneDeep, filter } from "lodash";
import React, { useCallback, useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";

import {
  mapUploadedDocument,
  uploadFile,
} from "../api/uploadFile";
import { IDocument } from "../common/constant";
import { UploadDocument } from "../components/UploadDocument";
import { DocumentList } from "../components/UploadDocument/DocumentList";
import { MAX_FILE_SIZE, MAX_NUMBER_OF_JOB_OPERATIONS, MB_TO_BYTE_MULTIPLIER } from "../common/constant";

export const Upload: React.FC = () => {
  const toast = useToast();
  const toast_error_id = 'error-toast';
  const [documentList, setDocumentList] = useState<IDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const maxFileSize = (MAX_FILE_SIZE * MB_TO_BYTE_MULTIPLIER);

  const handleFileSelect = (files: File[]) => {
    const data = files.map((f, index) => {
      const fileStatus = {
        "error": false,
        "message": "",
      };
      if (f.size >= maxFileSize) {
        fileStatus.error = true;
        fileStatus.message = ("File is larger than " + MAX_FILE_SIZE.toString() + " MB. Files of this size are not supported.");
      }
      return ({
        file: f,
        params: {},
        status: fileStatus,
        key: Date.now() + index,
      })
    });
    setDocumentList((d) => [...d, ...data]);
  };

  const handleDocumentChange = useCallback((document: IDocument) => {
    setDocumentList((dList) => {
      const index = dList.findIndex((d) => d.key === document.key);
      const list = cloneDeep(dList);
      list[index] = document;
      return list;
    });
  }, []);

  const handleDocumentDelete = useCallback(
    (document: IDocument) => {
      let list = filter(
        cloneDeep(documentList),
        (d, i) => d.key !== document.key
      );
      setDocumentList(list);
    },
    [documentList]
  );

  const handleClear = useCallback(() => {
    setDocumentList([]);
  }, []);

  const handleDocumentMapping = ({ data, document }: any) => {
    mapUploadedDocument({
      fileName: document?.file?.name,
      fileKey: data.key,
      ...(document?.params?.password && {
        password: document.params.password,
      }),
      ...(document?.params?.bank && {
        bankCode: document.params.bank.value,
      }),
      ...(document?.params?.parserParameters && {
        params: document.params.parserParameters,
      }),
      ...(document?.params?.pageRange && {
        pageRange: document.params.pageRange,
      }),
    }).then(async (res) => {
      const d = await res.json();
      if (d.status === "ok") {
        handleDocumentChange({
          ...document,
          status: {
            error: false,
            success: true,
            message: "Uploaded successfully",
          },
        });
      } else {
        handleDocumentChange({
          ...document,
          status: {
            error: true,
            success: false,
            message: d.tooltip_text,
          },
        });
      }
      setLoading(false);
    });
  };

  const setInProgress = (key: number, inProgressStatus = true) => {
    setDocumentList((documents: IDocument[]) => {
      const foundDocument = documents.find(d => d.key === key);
      if (foundDocument)
        foundDocument.status.inprogress = inProgressStatus;
      return documents;
    });
  }

  const handleUpload = useCallback(() => {
    const validDocumentList = documentList.filter((d: IDocument) => !d.status.error && !d.status.success); // no error and not already uploaded
    if (validDocumentList.length > 0) {
      if (validDocumentList.length <= MAX_NUMBER_OF_JOB_OPERATIONS) {
        setLoading(true);
        Promise.all(
          validDocumentList
            .map((document, index: number) => {
              setInProgress(document.key, true);
              return uploadFile(document).catch((error) => {
                handleDocumentChange({
                  ...document,
                  status: {
                    error: true,
                    success: false,
                    message: 'Upload file failed',
                    inprogress: false,
                  },
                });
                setLoading(false);
                return error;
              });
            })
        )
          .then((responses) => {
            responses.forEach(async (res: any, index) => {
              if (res && (res.name !== 'TypeError')) {
                const data = await res.response.json();
                const document = res.document;
                if (data.status === "ok") {
                  handleDocumentMapping({ data, document, index });
                } else if (data.error) {
                  handleDocumentChange({
                    ...document,
                    status: {
                      error: true,
                      message: data.error,
                    },
                  });
                  setInProgress(res.document.key, false);
                }
              }
            });
            setLoading(false);
          })
      } else {
        if (!toast.isActive(toast_error_id)) {
          toast({
            id: toast_error_id,
            title: "To many files",
            description: "Cannot upload more than " + MAX_NUMBER_OF_JOB_OPERATIONS + " files at a time. Remove some file(s).",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
  }, [documentList]);

  return (
    <Box minW="1080px" mt={6}>
      <RouterLink to={"../documents"}>
        <Button
          color="brand.300"
          leftIcon={<MdArrowBackIos fontSize="18px" />}
          variant="outline"
        >
          Back
        </Button>
      </RouterLink>
      <Box m={"auto"} width="1080px" mt="16px">
        <Flex w="100%" mb="8px">
          <Link
            color="blue.600"
            href="https://pdf-anonymizer.canopy.cloud/"
            isExternal
          >
            Click here to anonymize your file before uploading
          </Link>
        </Flex>
      </Box>
      <UploadDocument
        onFileSelect={handleFileSelect}
        isDisabled={loading}
      />
      {!!documentList?.length && (
        <Box my="32px">
          <DocumentList
            documentList={documentList}
            onDelete={handleDocumentDelete}
            onChange={handleDocumentChange}
            isDisabled={loading}
          />
          <Flex p={6} justifyContent="flex-end">
            <Button
              width="154px"
              fontSize="sm"
              variant="outline"
              mr="12px"
              onClick={handleClear}
              isDisabled={loading}
            >
              Clear
            </Button>
            {!!documentList.filter((d: IDocument) => !d.status.success)?.length && <Button
              width="154px"
              fontSize="sm"
              onClick={handleUpload}
              isDisabled={
                loading
              }
            >
              {loading && <Spinner size="sm" ml={"-21px"} mr={2} />} Upload
            </Button>}
            {!documentList.filter((d: IDocument) => !d.status.success)?.length && (
              <RouterLink to={"../documents"}>
                <Button
                  width="154px"
                  fontSize="sm"
                >
                  {loading && <Spinner size="sm" ml={"-21px"} mr={2} />} Go To Dashboard
                </Button>
              </RouterLink>
            )}
          </Flex>
        </Box>
      )}
    </Box>
  );
};
