import React from "react";
import jwt_decode from "jwt-decode";

// import jwt_decode from 'jwt-decode';
const LZString = require("lz-string");

export const getToken = () => {
  let token;
  const compressedData = localStorage.getItem("canopy-session");
  const decompressedData = LZString.decompressFromUTF16(compressedData);
  if (decompressedData) {
    const session = JSON.parse(decompressedData);
    token = session.token;
  }
  return token;
};

export const getParsedToken = (token?: string): Record<string, any> => {
  const tkn = (token||getToken());
  return jwt_decode(tkn);
};

export const useToken = () => {
  return getToken();
};
