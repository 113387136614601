import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Select, { ActionMeta, MultiValue } from "react-select";
import { IDocument, IDocumentParams, PARSER_PARAMETERS } from "../../../common/constant";
import { useData } from "../../../hooks/data";
import { useCurrentColors } from "../../../hooks/UseCurrentColors";
import { Spinner } from "@chakra-ui/react";
import { selectStyle } from "../../../theme/theme";
import { StyledInput } from "../../../theme/components/input";

const parserParameters = [
  {

    value: PARSER_PARAMETERS.USE_OCR,
    label: (
      <Tooltip label="Select for scanned PDF" placement='auto'>
        USE_OCR
      </Tooltip>
    ),
  },
  // {
  //   label: "USE_ABBYY_FOR_OCR",
  //   value: "USE_ABBYY_FOR_OCR",
  // },
  {
    value: PARSER_PARAMETERS.RUN_PDF_REPAIR,
    label: (
      <Tooltip label="Select for corrupt PDF" placement='auto' >
        RUN_PDF_REPAIR
      </Tooltip>
    ),
  },
  {
    value: PARSER_PARAMETERS.EUROPEAN_NUMBER_FORMAT,
    label: (
      <Tooltip label="Select for PDF with european number formats" placement='auto'>
        EUROPEAN_NUMBER_FORMAT
      </Tooltip>
    ),
  },
];

export const DocumentListItem: React.FC<{
  document: IDocument;
  isDisabled: boolean;
  inprogress?: boolean;
  onChange: (document: IDocument) => void;
  onDelete: (document: IDocument) => void;
}> = ({ document, isDisabled, inprogress = false, onChange, onDelete }) => {
  const currentColors = useCurrentColors();
  const { banks } = useData();

  const [params, setParams] = useState<IDocumentParams>({});
  const [page, setPage] = useState<string>("All");

  React.useEffect(() => {
    if (page === "All") {
      handleChange("pageRange", "");
    }
  }, [page]);

  const handleChange = (key: string, value: any) => {
    const data = { ...params, [key]: value };
    setParams(data);
    onChange({ ...document, params: data });
  };

  const handlePasswordChange = debounce((event) => {
    handleChange("password", event?.target?.value);
  }, 400);

  const handleParserParametersChange = useCallback(
    (
      newValue: MultiValue<{
        label: any;
        value: string;
      }>,
      actionMeta: ActionMeta<{
        label: any;
        value: string;
      }>
    ) => {
      const data = newValue.reduce((acc: { [k: string]: boolean }, res) => {
        if (res.value === PARSER_PARAMETERS.USE_OCR) {
          acc[PARSER_PARAMETERS.USE_ABBYY_FOR_OCR] = true;
        }
        acc[res.value] = true;
        return acc;
      }, {});
      handleChange("parserParameters", data);
    },
    [handleChange]
  );

  const handleStartRangeChange = debounce((event) => {
    const value = event?.target?.value;
    let start = `${value}-`;
    const page = params.pageRange;
    if (page) {
      start = page.replace(/(\d*)-(\d*)/, `${value || 0}-$2`);
    }
    handleChange("pageRange", start);
  }, 400);

  const handleEndRangeChange = debounce((event) => {
    const value = event?.target?.value;
    let end = `-${value}`;
    const page = params.pageRange;
    if (page) {
      end = page.replace(/(\d*)-(\d*)/, `$1-${value || 0}`);
    }
    handleChange("pageRange", end);
  }, 400);

  const handleDelete = useCallback(() => {
    onDelete(document);
  }, [document, onDelete]);

  const applyDropdownTheme = (theme: any) => ({
    ...theme,
    borderColor: "transparent",
    colors: {
      ...theme.colors,
      primary: currentColors.brand[500],
      primary25: currentColors.brand[800],
      primary50: currentColors.brand[800],
      natural190: "none",
    },
  });

  return (
    <Flex direction="column" mx={6} my={6} className="card">
      <Flex gap="16px" p={6} alignItems="center">
        <Flex minW="150px" w="15%" alignItems="center">
          <Text marginRight={2} wordBreak="break-word">
            {document.file.name}
          </Text>
          {inprogress && (
            <Box>
              <Spinner />
            </Box>
          )}
        </Flex>
        <Tooltip label="Will autodetect, if not provided" hasArrow>
          <Box minW="150px" w="15%">
            <Select
              name="Bank"
              placeholder="Bank"
              aria-label="Bank"
              isClearable
              styles={selectStyle(currentColors)}
              onChange={(value) => handleChange("bank", value)}
              options={banks?.map((bank) => ({
                label: bank?.bank_name,
                value: bank?.bank_code,
              }))}
              isDisabled={isDisabled || document.status?.success}
              theme={applyDropdownTheme}
            />
          </Box>
        </Tooltip>
        <Tooltip label="Required only for password protected files" hasArrow >
          <Box minW="150px" w="15%">
            <StyledInput
              placeholder="Password"
              borderRadius={"4px"}
              w="100%"
              onChange={handlePasswordChange}
              isDisabled={isDisabled || document.status?.success}
            />
          </Box>
        </Tooltip>
        <Tooltip label="Parser Parameters (Optional)" hasArrow >
          <Box minW="150px" w="15%">
            <Select
              name="Parser Parameters"
              placeholder="Parser Parameters (Optional)"
              aria-label="Parser Parameters"
              isClearable
              isMulti
              styles={selectStyle(currentColors)}
              onChange={handleParserParametersChange}
              options={parserParameters}
              isDisabled={isDisabled || document.status?.success}
              theme={applyDropdownTheme}
            />
          </Box>
        </Tooltip>
        <Flex
          gap="8px"
          alignItems="center"
          minW="320px"
          w="30%"
          justifyContent="center"
        >
          <Text> Pages: </Text>
          <RadioGroup
            value={page}
            onChange={setPage}
            isDisabled={document.status?.success}
          >
            <Stack direction="row">
              <Radio colorScheme={"lightPrimary"} value="All">
                All
              </Radio>
              <Radio colorScheme={"lightPrimary"} value="Range">
                Range
              </Radio>
            </Stack>
          </RadioGroup>
          <StyledInput
            type="number"
            placeholder="Start"
            borderRadius={"4px"}
            w="100px"
            disabled={
              isDisabled || page !== "Range" || document.status?.success
            }
            onChange={handleStartRangeChange}
          />
          <StyledInput
            type="number"
            placeholder="End"
            borderRadius={"4px"}
            w="100px"
            disabled={
              isDisabled || page !== "Range" || document.status?.success
            }
            onChange={handleEndRangeChange}
          />
        </Flex>
        <Flex alignItems="flex-end">
          <IconButton
            variant="ghost"
            aria-label="Delete File"
            isRound={false}
            icon={<AiOutlineCloseCircle fontSize="20px" />}
            onClick={handleDelete}
            isDisabled={isDisabled || document.status?.success}
          />
        </Flex>
      </Flex>
      {document.status?.error && (
        <Alert status="error">
          <AlertIcon />
          {document.status?.message}
        </Alert>
      )}
      {document.status?.success && (
        <Alert status="success" variant="subtle">
          <AlertIcon />
          {document.status?.message}
        </Alert>
      )}
    </Flex>
  );
};
