import { mode } from '@chakra-ui/theme-tools';
export const Checkbox = {
    parts: ['icon', 'container', 'control', 'label'],
    baseStyle: (props: any) => ({
        container: {
            fontSize: '15px',
            borderColor: mode('light.brand.700', 'dark.brand.700')(props),
        },
        label: {
            'font-size': '15px',
            color: mode('light.brand.10', 'dark.brand.10')(props),
        },
    }),
    variants: {},
};
