export const IconButton = {
    // The styles all button have in common
    baseStyle: {
        cursor: 'pointer',
        background: '#FFF',
        borderRadius: '40px',
    },
    variants: {
        navbar: (props: any) => ({
            width: '35px',
            height: '35px',
            color: 'navbarTextColor',
            bg: 'navbarIconBg',
            borderRadius: '40px',
            border: 'none',
            padding: '10px',
            _focus: {
                border: '0px',
                outline: 'none',
            },
        }),
    },
};
