import { mode } from '@chakra-ui/theme-tools';
export const Button = {
    // The styles all button have in common
    baseStyle: (props: any) => ({
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '100%',
        margin: '0',
        color: 'inherit',
        textTransform: 'none',
        _focus: {
            outline: 'none',
            boxShadow: 'none',
        },
        _hover: {
            outline: 'none',
            boxShadow: 'none',
        },
        borderRadius: '4px'
    }),
    variants: {
        solid: (props: any) => ({
            bg: mode('lightPrimary.500', 'darkPrimary.500')(props),
            color: 'white',
            border: '1px solid',
            borderColor: mode('lightPrimary.500', 'darkPrimary.500')(props),
            _hover: {
                bg: mode('lightPrimary.500', 'darkPrimary.500')(props),
                outline: 'none',
                boxShadow: 'none',
            },
            _active: {
                bg: mode('lightPrimary.500', 'darkPrimary.500.500')(props),
                outline: 'none',
                boxShadow: 'none',
            },
            _disabled: {
                backgroundColor: mode(
                    'light.brand.800 !important',
                    'dark.brand.800 !important'
                )(props),
                color: mode('light.brand.500', 'dark.brand.500')(props),
                borderColor: mode('light.brand.600', 'dark.brand.600')(props),
                opacity: 1,
            },
        }),
        outline: (props: any) => ({
            color: mode('light.brand.100', 'dark.brand.100')(props),
            border: '1px solid',
            borderColor: mode('light.brand.600', 'dark.brand.600')(props),
            _hover: {
                background: 'transparent',
                outline: 'none',
                boxShadow: 'none',
            },
            _active: {
                background: 'transparent',
                outline: 'none',
                boxShadow: 'none',
            },
            _disabled: {
                backgroundColor: mode(
                    'light.brand.800 !important',
                    'dark.brand.800 !important'
                )(props),
                color: mode('light.brand.500', 'dark.brand.500')(props),
                opacity: 1,
            },
        }),
        ghost: (props: any) => ({
            cursor: 'pointer',
            _hover: {
                background: 'transparent',
                outline: 'none',
                boxShadow: 'none',
            },
            _active: {
                background: 'transparent',
                outline: 'none',
                boxShadow: 'none',
            },
            _disabled: {
                backgroundColor: 'transparent',
                color: mode('light.brand.500', 'dark.brand.500')(props),
                opacity: 1,
            },
        }),
        navbar: (props: any) => ({
            width: '35px',
            height: '35px',
            color: mode('light.navbarTextColor', 'dark.navbarTextColor')(props),
            bg: mode('light.navbarIconBg', 'dark.navbarIconBg')(props),
            borderRadius: '40px',
            border: 'none',
            padding: '10px !important',
            _focus: {
                border: '0px',
                outline: 'none',
            },
        }),
        pageComponent: (props: any) => ({
            height: '30px',
            border: '1px solid',
            borderColor: mode(
                'light.componentBorder',
                'dark.componentBorder'
            )(props),
            bg: mode('light.componentBg', 'dark.componentBg')(props),
            color: mode('light.navbarTextColor', 'dark.navbarTextColor')(props),
            _focus: {
                borderColor: mode(
                    'light.componentBorder',
                    'dark.componentBorder'
                )(props),
                outline: 'none',
            },
        }),
    },
};
